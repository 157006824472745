import React from "react";

import {
  Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import Home from "./Home";
import Card from "./Card";

import './App.css';
import 'bulma/css/bulma.min.css';

const trackingId = "UA-512885-18";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

ReactGA.pageview(window.location.pathname + window.location.search);

history.listen(location => {
  ReactGA.pageview(window.location.pathname + window.location.search);
});

export default class App extends React.Component {

  constructor() {
    super();

    this.state = {
      cards: [],
      show_deprecated: false,
      show_only_with_images: false,
      order_by: 'stars',
      order_direction: 'desc', // asc
      view: 'table', // cards
    };
  }

  componentDidMount() {

    var _this = this;

    fetch("/cards.json",
    {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
    })
    .then(function(response) {
        return response.json();
    })
    .then(function(data) {
        _this.setState({
          cards : data,
        });
      }
    );

  }

  render() {
  return (
<div>

  <Router history={history}>

  <nav className="navbar is-light" role="navigation" aria-label="main navigation">
    <div id="navbar" className="navbar-menu is-active">
      <div className="navbar-start">
        <Link className="navbar-item" to="/">Main</Link>
      </div>
    </div>
  </nav>

  <section className="section">
    <div className="container">

        <Switch>

          <Route exact path="/">
            <Home cards={this.state.cards}/>
          </Route>

          <Route path="/card/:cardName">
            <Card cards={this.state.cards}/>
          </Route>>

          <Route path="/tag/:tagName">
            <Home cards={this.state.cards}/>
          </Route>>

        </Switch>

    </div>
  </section>

  </Router>

</div>
  );
  }


}
