import React from "react";

export default class Tag extends React.Component {
  render() {

    if (this.props.isActive) {
      return (
        <span className="tag is-info is-medium">
          { this.props.label }
          <span className="delete is-small" style={{backgroundColor: '#1d6496'}}></span>
        </span>
      );
    } else {
      return (
        <span className="tag is-info is-light is-medium">
          { this.props.label }
        </span>
      );
    }

  }
}
