import moment from 'moment';
import React from "react";
import { withRouter } from "react-router";

import {
  Link
} from "react-router-dom";

import Tag from "./Tag";

class Home extends React.Component {

    constructor() {
      super();

      this.state = {
        show_deprecated: false,
        show_only_with_images: false,
		order_by: 'stars',
		order_direction: 'desc', // asc
        view: 'table', // cards
      };

      this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
      this.handleShowOnlyWithImagesClick = this.handleShowOnlyWithImagesClick.bind(this);
      this.handleOrderByChange = this.handleOrderByChange.bind(this);
      this.handleViewChange = this.handleViewChange.bind(this);
    }

    componentDidMount() {

      document.title = "Home Assisant Cards";

      var elements = document.cookie.split(';');

      for (var i = 0; i < elements.length; i++) {
        elements[i] = elements[i].trim()
      }

      if(elements.includes("control_show_deprecated=1")){
		this.setState({
		  show_deprecated: true,
		});
      }

      if(elements.includes("control_show_only_with_images=1")){
		this.setState({
		  show_only_with_images: true,
		});
      }

      if(elements.includes("control_view=cards")){
		this.setState({
		  view: 'cards',
		});
      }
    }

    handleCheckboxClick(event) {

        var value = this.state.show_deprecated ? "0" : "1";

        document.cookie = "control_show_deprecated=" + value + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";

		this.setState({
		  show_deprecated: !this.state.show_deprecated,
		});
    }

    handleShowOnlyWithImagesClick(event) {

        var value = this.state.show_only_with_images ? "0" : "1";

        document.cookie = "control_show_only_with_images=" + value + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";

		this.setState({
		  show_only_with_images: !this.state.show_only_with_images,
		});
    }

    handleOrderByChange(event) {

      var name = event.currentTarget.dataset.name;

      if (name === this.state.order_by) {
        this.setState({
          order_direction: (this.state.order_direction === 'asc' ? 'desc' : 'asc'),
        });
      } else {
        this.setState({
        order_by: name,
        });
      }

    }

    handleViewChange(event) {
      var name = event.currentTarget.dataset.name;

      document.cookie = "control_view=" + name + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";

      this.setState({
        view: name,
      });
    }

	render() {
      var _this = this;

      var content = [];
      var count = 0;

      var tags = {};

      this.props.cards.forEach(function(card){
          if ('tags' in card) {
              card.tags.forEach(function(t) {
                  tags[t] = 1;
              });
          }
      });

      if (this.state.view === 'table') {
	    var table_data = [];
	    var i = 1;

        var sorted_cards = this.props.cards;
        sorted_cards.sort(function(a, b){

          if (_this.state.order_by === 'name') {
	        if (_this.state.order_direction === 'asc') {
              return ('' + b.name).localeCompare(a.name);
            } else {
              return ('' + a.name).localeCompare(b.name);
            }
          } else if (_this.state.order_by === 'created_at') {
	        if (_this.state.order_direction === 'asc') {
              return ('' + a.created_at).localeCompare(b.created_at);
            } else {
              return ('' + b.created_at).localeCompare(a.created_at);
            }
          } else if (_this.state.order_by === 'last_commit') {
	        if (_this.state.order_direction === 'asc') {
              return ('' + a.last_commit).localeCompare(b.last_commit);
            } else {
              return ('' + b.last_commit).localeCompare(a.last_commit);
            }
          } else if (_this.state.order_by === 'stars') {
	        if (_this.state.order_direction === 'asc') {
              return (a.github_stars - b.github_stars);
            } else {
              return (b.github_stars - a.github_stars);
            }
          }

          return 1;
	    });

	    sorted_cards.forEach(function(card){
          var link_text = '';
	  	if (card.site_type === 'github') {
	  		link_text = 'GitHub';
	  	} else {
	  		link_text = 'site';
	  	}

          var card_status = [];

          if (_this.props.match.params.tagName !== undefined) {
            if (!('tags' in card)) {
                return;
            }

            var found = false;

            card.tags.forEach(function(t) {
                if (_this.props.match.params.tagName === t) {
                    found = true;
                }
            });

            if (!found) {
                return;
            }
          }

          if (card.status === 'deprecated') {
              if (!_this.state.show_deprecated) {
                  return;
              }
              card_status.push(<br key={ "key_br_deprecated_" + i }/>);
              card_status.push(<span key={ "key_span_deprecated_" + i } className="deprecated">deprecated</span>);
          }

          if (card.status === 'deleted') {
              if (!_this.state.show_deprecated) {
                  return;
              }
              card_status.push(<br key={ "key_br_deleted_" + i }/>);
              card_status.push(<span key={ "key_span_deleted_" + i } className="deprecated">deleted</span>);
          }

        var created_at_text = '';
        if (card.created_at) {
            created_at_text = moment(card.created_at).fromNow();
        }

        var last_commit_text = '';
        if (card.last_commit) {
            last_commit_text = moment(card.last_commit).fromNow();
        }

	  	table_data.push(<tr key={ 'key_tr_' + i }>
	    	  <td className="has-text-right">{ i }</td>
	    	  <td><Link to={ "/card/" + card.name }>{ card.name }</Link>{ card_status }</td>
	    	  <td className="td-number">{ card.github_stars }</td>
	    	  <td className="td-number is-hidden-mobile" title={ card.created_at }>{ created_at_text }</td>
	    	  <td className="td-number is-hidden-mobile" title={ card.last_commit }>{ last_commit_text }</td>
	    	  <td><a href={ card.site }>{ link_text }</a></td>
	  	</tr>);

          i++;
          count++;
	    });

        var sort_symbol_name = '';
        var sort_symbol_stars = '';
        var sort_symbol_created_at = '';
        var sort_symbol_last_commit = '';

        if (this.state.order_by === 'name') {
          sort_symbol_name = this.state.order_direction === 'asc' ? '▲' : '▼';
        } else if (this.state.order_by === 'stars') {
          sort_symbol_stars = this.state.order_direction === 'asc' ? '▲' : '▼';
        } else if (this.state.order_by === 'created_at') {
          sort_symbol_created_at = this.state.order_direction === 'asc' ? '▲' : '▼';
        } else if (this.state.order_by === 'last_commit') {
          sort_symbol_last_commit = this.state.order_direction === 'asc' ? '▲' : '▼';
        }

        content.push(
          <div key="mainContent">
			<table className="table">
			<thead>
			<tr>
			<th className="has-text-right">#</th>
			<th className="th-click no-select" onClick={this.handleOrderByChange} data-name='name'>name <div className="sort-arrow">{ sort_symbol_name }</div></th>
			<th className="th-click no-select" onClick={this.handleOrderByChange} data-name='stars'>stars <div className="sort-arrow">{ sort_symbol_stars }</div></th>

			<th className="th-click no-select is-hidden-mobile" onClick={this.handleOrderByChange} data-name='created_at'>
              <span title="Date and time when the repository was created">created </span>
              <div className="sort-arrow">{ sort_symbol_created_at }</div>
            </th>

			<th className="th-click no-select is-hidden-mobile" onClick={this.handleOrderByChange} data-name='last_commit'>
              <span title="Date and time of the last commit in default branch">last commit </span>
              <div className="sort-arrow">{ sort_symbol_last_commit }</div>
            </th>

			<th>link</th>
			</tr>
			</thead>
			<tbody>
			{ table_data }
			</tbody>
			</table>
          </div>
		);
      } else if (this.state.view === 'cards') {

        this.props.cards.forEach(function(card){

          if (_this.props.match.params.tagName !== undefined) {
            if (!('tags' in card)) {
                return;
            }

            var found = false;

            card.tags.forEach(function(t) {
                if (_this.props.match.params.tagName === t) {
                    found = true;
                }
            });

            if (!found) {
                return;
            }
          }

          if ((card.status === 'deprecated') || (card.status === 'deleted')) {
              if (!_this.state.show_deprecated) {
                  return;
              }
          }

          if ( (card.image_link === 'no-image') && _this.state.show_only_with_images) {
          	return;
          }

          var image_link = '';
          var image_width;
          var image_height;

          if (card.image_link === 'no-image') {
            image_link = 'https://upload.bessarabov.ru/bessarabov/56TB3kS7H_hOHA8BOG3w_eAR3Rk.png';
            image_width = 492;
            image_height = 120;
          } else {
            image_link = card.image_link;
          }

          content.push(
          <Link key={"key_a_" + count} className="card-container" to={ "/card/" + card.name }>
          <img alt="" className="card-image" src={ image_link } width={ image_width } height={ image_height } loading="lazy"/>
          <div className="just-black">{ card.name }</div>
          </Link>
          );

          count++;
        });
      }

      var secondLabel = [];

		if (this.state.view === 'cards') {

	secondLabel.push(
<label key={"key_label_checkbox_showOnlyWithImages" } className="checkbox no-select" htmlFor="showOnlyWithImages">
  <input
	type="checkbox"
	id="showOnlyWithImages"
    checked={this.state.show_only_with_images}
    onChange={this.handleShowOnlyWithImagesClick}
  />
  &nbsp;Show only with images
</label>
		);
		}

        var tagsList = [];
        var tagsContent = [];

        for (let t in tags) {
            tagsList.push(t);
        }

        tagsList.sort(function(a, b){
            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
        })

        tagsList.forEach(function(t) {
            if (_this.props.match.params.tagName !== undefined && t === _this.props.match.params.tagName) {
                tagsContent.push(
                    <span key={"key_tag_" + t}>
                        <Link to={ "/" }>
                            <Tag label={ t } isActive={true}/>
                        </Link>
                        &nbsp;
                    </span>
                );
            } else {
                tagsContent.push(
                    <span key={"key_tag_" + t}>
                        <Link to={ "/tag/" + t }>
                            <Tag label={ t }/>
                        </Link>
                        &nbsp;
                    </span>
                );
            }
        });

	  return <div>

<div className="notification is-info is-light">
This is the list of Home Assistant UI cards.
The idea is to create the most complete list of such cards.
If you know the card that is not listed here, can you please create a PR with the card data
(<a href="https://github.com/bessarabov/home-assistant-cards/blob/master/docs/how_to_add_information_about_new_card.md">Some instructions</a>), or maybe just create a <a href="https://github.com/bessarabov/home-assistant-cards/issues/new">GitHub Issue</a> with a link to the missing card.

</div>

<div className="buttons has-addons">
  <button className={ this.state.view === 'table' ? 'button is-info is-selected' : 'button' } onClick={this.handleViewChange} data-name='table'>Table</button>
  <button className={ this.state.view === 'cards' ? 'button is-info is-selected' : 'button' } onClick={this.handleViewChange} data-name='cards'>Cards</button>
</div>

<label className="checkbox no-select first-label" htmlFor="showDeprecated">
  <input
	type="checkbox"
	id="showDeprecated"
    checked={this.state.show_deprecated}
    onChange={this.handleCheckboxClick}
  />
  &nbsp;Show deprecated
</label>

{ secondLabel }


<br/>
<br/>

Tags:&nbsp;
{ tagsContent }

<br/>
<br/>

<h4 className="title is-4">{ count } cards</h4>

{ content }
</div>

	}

}

export default withRouter(Home);
