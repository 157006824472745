import React from "react";
import { withRouter } from "react-router";

import {
  Link
} from "react-router-dom";

import Tag from "./Tag";

class Card extends React.Component {

  constructor() {
    super();

    this.state = {
      cardName: '',
    };
  }

  componentDidMount() {

    const cardName = this.props.match.params.cardName;
    this.setState({cardName: cardName});

    document.title = cardName;

  }

  render() {
    var _this = this;

    var neededCards = [];

    this.props.cards.forEach(function(c) {
        if (c.name === _this.state.cardName) {
            neededCards.push(c)
        }
    });

    var content = [];

    var severalCards = neededCards.length > 1;

    if (severalCards) {
        content.push(<div key="div_several">There are several cards with this name.</div>);

        function compare( a, b ) {
          if ( a.github_stars < b.github_stars ){
            return 1;
          }
          if ( a.github_stars > b.github_stars ){
            return -1;
          }
          return 0;
        }

        neededCards.sort( compare );
    }

    var i = 1;
    neededCards.forEach(function(c) {

        if (severalCards) {
            content.push( <h2 key={"key_h2_" + i} className="subtitle" style={{paddingTop: '2.5em'}}>{ i }</h2> );
        }

        if (c.status === "deprecated") {
            content.push(<span key={"span_deprecated_text_" + i} className="deprecated">deprecated</span>);
            content.push(<span key={"span_nbsp" + i}>&nbsp;</span>);
        } else if (c.status === "deleted") {
            content.push(<span key={"span_deleted_text_" + i} className="deprecated">deleted</span>);
            content.push(<span key={"span_nbsp" + i}>&nbsp;</span>);
        }

        content.push(<a key={"key_a_site" + i} href={ c.site }>{ c.site }</a>);

        content.push(<br key={"key_br_1_" + i }/>);
        content.push(<br key={"key_br_2_" + i }/>);

        if (c.status !== "deleted") {
            content.push(
                <table key={"key_table_" + i} className="table">
                <tbody>
                <tr>
                <td className="td-left-align">GitHub stars</td>
                <td className="has-text-right">{ c.github_stars }</td>
                </tr>
                <tr>
                <td className="td-left-align">GitHub forks</td>
                <td className="has-text-right">{ c.github_forks }</td>
                </tr>
                </tbody>
                </table>
            );
        }

        if (c.image_link !== "no-image") {
            content.push(<img key={"key_image_" + i} alt="" className="card-image" src={ c.image_link }/>);
        } else {
            content.push(<span key={"key_no_image_" + i}>There is no image for the card. Here are <a href="https://github.com/bessarabov/home-assistant-cards/blob/master/docs/how_to_add_image.md">the instructions how to add an image</a>.</span>);
        }

        var firstChar = c.card_id.charAt(0);
        var secondChar = c.card_id.charAt(1);
        var restChars = c.card_id.substring(2);

        var github_link = "https://github.com/bessarabov/home-assistant-cards/blob/master/data/" + firstChar + "/" + secondChar + "/" + restChars + ".json";

        if ('tags' in c) {
            var tagsContent = [];

            c.tags.forEach(function(t) {
                tagsContent.push(
                    <span key={"key_tag_" + t}>
                        <Link to={ "/tag/" + t }>
                            <Tag label={ t }/>
                        </Link>
                        &nbsp;
                    </span>
                );
            });

            content.push(<br key={"key_br_before_tags_1_" + i }/>);
            content.push(<br key={"key_br_before_tags_2_" + i }/>);
            content.push(<span key={"key_span_tags_" + i}>Tags:&nbsp;</span>);
            content.push(tagsContent);
        }

        content.push(<br key={"key_br_3_" + i }/>);
        content.push(<br key={"key_br_4_" + i }/>);
        content.push(<br key={"key_br_5_" + i }/>);
        content.push(<a key={"key_a_github_" + i} href={ github_link }>Edit card info at GitHub</a>);

        i++;
    });

    return (
      <div>
        <h1 className="title"><span style={{paddingRight: '1em'}}>{ this.state.cardName }</span></h1>

        { content }

        <br/>
      </div>
    )
  }
}

export default withRouter(Card);
